<template>
    <div class="layer">
        <div class="layerCatalog  box-shadow" v-loading="loading">
            <div class="layerCatalogTool">
                <div>文件目录</div>
                <div>
                    <i class="item iconfont iconadd" @click="add" title="新增目录"></i>
                </div>
            </div>
            <el-input class="filterCatalogText" v-model="filterCatalogText" placeholder="请输入名称查询" size="small"></el-input>
            <el-scrollbar class="sidebar-wrapper">
                <el-tree ref="catalogTree" :filter-node-method="filterNode" :data="data" default-expand-all :expand-on-click-node="true">
                    <div class="custom-tree-node" slot-scope="{ node, data }" :class="{'active':activeData.id==data.id,'leafNode':data.leafNode}">
                        <span class="name" @click="catalogNameClick(data)">{{  data.name  }}</span>
                        <div class="control" v-if="data.leafNode">
                            <i class="iconfont icondelete1" @click="del(data)"></i>
                        </div>
                    </div>
                </el-tree>
            </el-scrollbar>
        </div>
        <div class="mainList" v-loading="fileLoading">
            <object v-if="activeFile.fileName" class="mainList_content" style="padding:0;" classid="clsid:CA8A9780-280D-11CF-A24D-444553540000" width="100%" height="100%" border="0">
                <param name="_Version" value="65539">
                <param name="_ExtentX" value="20108">
                <param name="_ExtentY" value="10866">
                <param name="_StockProps" value="0">
                <param name="SRC" :value="activeFile.fileName | fileAddress">
                <embed :src="activeFile.fileName | fileAddress" width="100%" height="100%" :href="activeFile.fileName | fileAddress"></embed>
            </object>
            <div v-else  class="mainList_content" >
                <el-result icon="info" title="信息提示" subTitle="点击左侧文件名称查看"></el-result>
            </div>
        </div>
        <Edit :refresh="refresh" :isShow.sync="showEditDialog" :options="options"></Edit>
    </div>
</template>
<script>

export default {
    components: {
        Edit: () => import("./Edit.vue"),
        TableColumnSetting: () => import("@/components/TableColumnSetting.vue"),
    },
    data() {
        return {
            loading: false,
            fileLoading: false,
            showEditDialog: false,
            options: {},
            data:[],
            filterCatalogText:'',
            activeData:{},
            activeFile:{},
        };
    },
    watch:{
        filterCatalogText(val){
            this.$refs.catalogTree.filter(val);
        }
    },
    created() {
        this.getData();
    },
    mounted(){},
    methods: {
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        catalogNameClick(r){
            if(r.leafNode){
                this.activeData = r;
                this.fileLoading = true;
                this.$api['admin/investmentPolicy'].getById(r.id).then(res => {

                    this.activeFile = res.data.attachments[0];
                    this.fileLoading = false;
                }).catch(err => {
                    this.fileLoading = false;
                    console.log(err);
                });
            };
        },
        refresh(){
            this.showEditDialog = false;
            this.getData();
        },
        add() {
            this.options = {
                attachmentVos:[{
                    fileName:'',
                    fileAddress:'',
                }]
            };
            this.showEditDialog = true;
        },
        del(row) {
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$api['admin/investmentPolicy'].del({ids:[row.id]}).then(res => {

                    this.$message.success("删除成功！");
                    this.getData();

                }).catch(err => {
                    console.log(err);
                });
            }).catch(() => {//用户取消

            });
        },
        getData(){
            this.loading = true;
            this.$api['admin/investmentPolicy'].findByList().then(res => {

                this.loading = false;
                var obj = {};
                var arr = [];
                res.data.some(r=>{
                    if(!obj[r.level]){
                        obj[r.level] = {
                            id:r.level,
                            name:r.level,
                            children:[],
                            leafNode:false,
                        }
                    }
                    obj[r.level].children.push({
                        id:r.id,
                        name:r.title,
                        leafNode:true,
                    })
                });

                for(let k in obj){
                    arr.push(obj[k])
                }
                this.data = arr;

            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
.layer {
    padding-left:320px;
    .layerCatalog {
        position:absolute;
        top:20px;
        bottom:20px;
        left:20px;
        width:300px;
        background: #fff;
        padding: 15px;
        border-radius: 5px;
        overflow:auto;
        font-size:14px;
        .layerCatalogTool {
            display:flex;
            justify-content:space-between;
            height:47px;
            align-items:center;
            padding-bottom:10px;
            border-bottom:#F0F0F0 solid 1px;
            font-size:16px;
            .iconfont {
                font-size:24px;
                color:$themeColor;
                cursor:pointer;
                &.loading {
                    cursor:progress;
                }
            }
            .item {
                +.item {margin-left:10px;}
            }
        }
        .filterCatalogText {
            margin:10px 0;
        }
        .sidebar-wrapper {
            height:calc(100% - 99px);
            /deep/.el-scrollbar__wrap {
                overflow-x: hidden;
            }
            /deep/.el-tree {
                .el-tree-node__content {
                    background:none;


                }
                .custom-tree-node {
                    position:relative;
                    width:100%;
                    cursor:pointer;
                    &.leafNode {
                        cursor:auto;
                        &:hover {
                            color:$themeColor;
                            .control {display:flex;}

                        }
                        &.active {
                            color:$themeColor;
                        }
                        .name {cursor:pointer;}
                    }

                    .control {
                        position:absolute;
                        top:0;
                        right:0;
                        bottom:0;
                        display:none;
                        justify-content:space-between;
                        align-items:center;
                        .iconfont {
                            font-size:16px;
                            cursor:pointer;
                            +.iconfont {
                                margin-left:5px;
                            }
                            &.iconbianji {color:$themeColor;}
                            &.icondelete1 {color:$danger;}
                        }
                    }
                }
            }
        }
    }
}
</style>
